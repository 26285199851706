<template>
  <v-row align="center" justify="center" class="text-center">
    <v-btn class="ml-4 mb-4"
      ><download-excel
        class="btn btn-default"
        :data="data"
        :fields="json_fields"
        worksheet="My Worksheet"
        :name="`รายการออเดอร์${titleName}ทั้งหมด.xls`"
        :header="'รายการออเดอร์ Power Ballทั้งหมด'"
      >
        <img src="@/assets/Excel-Logo.png" width="50px" />
        Export Order
      </download-excel></v-btn
    >
  </v-row>
</template>
<script>
import moment from "moment";
export default {
  // :formDate="datefrom"
  // :toDate="dateto"
  props: ["data", "titleName"],
  data() {
    return {
      json_fields: {
        หมายเลขคำสั่งซื้อ: {
          field: "transactionNo",
          callback: (value) => {
            return "'" + value.toString();
          },
        },
        วันที่สั่ง: {
          field: "order.createdAt",
          callback: (value) => {
            return moment(value).format("MM/DD/YYYY HH:mm");
          },
        },
        "ชื่อ-นามสกุลผู้สั่ง": {
          field: "user",
          callback: (value) => {
            return `${value.firstName}  ${value.lastName}`;
          },
        },
        ราคา: "totalPrice",
        เลขที่ซื้อ: "selectedToStr",
        สถานะ: "status",
      },

      json_meta: [
        [
          {
            key: "charset",
            value: "utf-8",
          },
        ],
      ],
      list1: [],
    };
  },
  async created() {
    // this.getREportXray();
  },
  methods: {
    // async getREportXray() {
    //   const response = await this.axios.get(
    //     `${process.env.VUE_APP_API}/form/xrayReport`
    //   );
    //   console.log("data", response.data.data[0]);
    //   this.list1 = response.data.data[0];
    // },
  },
};
</script>
