<template>
  <div>
    <ManagePowerballOrder />
  </div>
</template>
<script>
import ManagePowerballOrder from "@/components/Order/ManagePowerballOrder";
export default {
  components: {
    ManagePowerballOrder,
  },
  created() {
  },
};
</script>